import { initSeo } from "#app/utils/seo.ts";

export const { getSeo, getSeoMeta, getSeoLinks } = initSeo({
	// Pass any SEO defaults for your site here.
	// If individual routes do not provide their own meta and link tags,
	// the tags generated by the defaults will be used.
	title: "Ryan Cunningham",
	titleTemplate: "%s | Ryan Cunningham",
	description: "Ryan Cunningham's blog",
});
