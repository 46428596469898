import { invariantResponse } from '@epic-web/invariant'
import {
	type MetaFunction,
	json,
	type LoaderFunctionArgs,
} from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import { format, formatDistanceToNow } from 'date-fns'
import Markdown from 'react-markdown'
import { getSeoMeta } from '#app/seo.ts'
import { prisma } from '#app/utils/db.server.ts'

export async function loader({ params }: LoaderFunctionArgs) {
	const post = await prisma.post.findUnique({
		where: { slug: params.slug, publishedAt: { not: null } },
		select: {
			id: true,
			title: true,
			content: true,
			slug: true,
			metaDescription: true,
			author: {
				select: {
					name: true,
				},
			},
			tags: {
				select: {
					name: true,
					slug: true,
				},
			},
			updatedAt: true,
			publishedAt: true,
		},
	})

	invariantResponse(post, 'Not found', { status: 404 })

	const date = new Date(post.updatedAt)
	const timeAgo = formatDistanceToNow(date)

	return json({
		post,
		timeAgo,
	})
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	if (data)
		return [
			...getSeoMeta({
				title: data?.post.title,
				description:
					data?.post.metaDescription ||
					`Post by ${data?.post.author.name}: ${data?.post.title}`,
			}),
		]
	return []
}

export default function SlugRoute() {
	const { post } = useLoaderData<typeof loader>()

	return (
		<article className="prose mx-auto max-w-3xl text-foreground dark:prose-invert sm:prose-lg lg:prose-xl">
			<div className="flex items-center gap-x-4 text-xs">
				{post.publishedAt && (
					<>
						<time dateTime={post.publishedAt} className="">
							{format(post.publishedAt, 'MMM dd, yyyy')}
						</time>
						{/* {post.publishedAt !== post.updatedAt && (
							<div>
								(Updated:&nbsp;
								<time
									property="schema:dateModified"
									dateTime={post.updatedAt}
									className=""
								>
									{format(post.updatedAt, 'MMM dd, yyyy')}
								</time>
								)
							</div>
						)} */}
					</>
				)}
				{!!post.tags.length &&
					post.tags.map((tag) => (
						<Link
							to={`/tags/${tag.slug}`}
							key={`post-tag-${tag.slug}`}
							className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium hover:bg-gray-100"
						>
							{tag.name}
						</Link>
					))}
			</div>
			<div className="group relative mt-2">
				<h1 className="font-bold tracking-tight text-foreground">
					{post.title}
				</h1>
				<p className="text-mu text-sm">By {post.author.name}</p>
			</div>
			<Markdown className="">{post.content}</Markdown>
		</article>
	)
}
